import { Env } from "../Env";

export const 링크 = {
  마켓봄_개인정보처리방침: "/term/privacy",
  마켓봄_이용약관: "/term/policy",
  마켓봄_전자금융거래_이용약관: "/term/privacyfinancetermspolicy",
  마켓봄_회원가입: `${Env.VENDOR_URL}/vendor/join`,
  마켓봄_간편가입_PC: `${Env.VENDOR_URL}/vendor/simple_member/join?grade_type=1`,
  마켓봄_간편가입_모바일: `${Env.VENDOR_URL}/vendor_mobile/simple_member/join_step2?grade_type=1`,
  마켓봄_유통사: `${Env.VENDOR_URL}`,
  마켓봄_거래처: `${Env.SHOP_URL}`,
  마켓봄_이용가이드: "/user_guide",
  마켓봄_자세히보기: "/service_marketbom",
  마켓봄도매: `${Env.WHOLESALESVENDOR_URL}`,
  마켓봄도매_회원가입: `${Env.VENDOR_URL}/vendor/member/join?grade_type=2&pos_grade=1`,
  마켓봄도매_회원가입_모바일: `${Env.VENDOR_URL}/vendor_mobile/member/join_step2?grade_type=2&pos_grade=1`,
  마켓봄도매_자세히보기: "/service_marketbomdome",
  데모신청: "/demo",
  바우처신청: "/voucher",
  비대면바우처: "https://mkt.marketbom.com/voucher",
  거래채앱_구글플레이:
    "https://play.google.com/store/apps/details?id=com.canals.marketboro.shop",
  거래채앱_앱스토어:
    "https://itunes.apple.com/kr/app/makesbolo-sigjajae-balju-aeb/id1099676457",
  유통사앱_구글플레이:
    "https://play.google.com/store/apps/details?id=com.marketboro.marketbom.vendor",
  Youtube: "https://www.youtube.com/channel/UCj3yLVkhgHL_xJQEviILyng",
  Blog: "https://blog.naver.com/marketbom",
  원격상담신청: "https://www.whelper.co.kr/client/main.do",
  전화: "tel:15229559",
  랜딩_영상: [
    "Cfv28F1Iy5E",
    "__S_aDq1V6g",
    "bhCV_Yo06ME",
    "QOkKivEOC2M",
    "SF5KV7ZjAV0",
  ],
  이용가이드_영상: {
    기업정보보기: "U81NTkK94oo",
    주문관리1: "3XmM1jkptLQ",
    주문관리2: "E7UPFSDwo_w",
    상품관리: "kUfkN5kbMOg",
    거래처관리1: "1cPl_GaDha8",
    거래처관리2: "OzAE_pW5sag",
    브랜드관리: "qzRsYbVdupg",
  },
  마켓봄_간편가입_실험_링크_PC: `${Env.VENDOR_URL}/vendor/simple_member/simple_member_join`,
  마켓봄_간편가입_실험_링크_모바일: `${Env.VENDOR_URL}/vendor_mobile/simple_member/simple_member_join`,
} as const;
